<div class="team-container d-flex fd-column" *transloco="let t">
  <h2>{{ t("team") }}</h2>
  <div class="flex gap-40 f-wrap resources">
    @for (resource of companyLocation.resources; track resource) {
    <div class="d-flex resource fd-column" (click)="navigate(resource.id)">
      <div class="avatar flex ai-center jc-center">
        @if(resource.avatarUrl ){
        <app-image
          [imageSrc]="resource.avatarUrl"
          [isAvatar]="true"
          [alt]="t('photo')"
        ></app-image>
        }@else{
        <div class="initial">{{ resource.firstName | slice : 0 : 1 }}</div>
        }
      </div>
      <div class="first-name text-ellipsis">{{ resource.firstName }}</div>
    </div>
    }
  </div>
</div>
