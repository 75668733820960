import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';

import { Company } from '../../model/company';
import { WorkingHoursPipe } from '../../pipes/working-hours.pipe';
import { SelectServiceComponent } from './select-service/select-service.component';
import { CompanyLocation } from '../../model/company-location';
import { environment } from '../../../../environments/environment';
import { Service } from '../../model/service';

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    WorkingHoursPipe,
    SelectServiceComponent,
    TranslocoDirective,
  ],
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss',
})
export class ServicesComponent {
  @Input() companyInfo!: Company;
  @Input() companyLocation!: CompanyLocation;
  @Input() locationServices: Service[] = [];
  @ViewChild('direction') direction!: ElementRef;

  constructor(private translocoService: TranslocoService) {}

  /**
   * Navigates to the booking site.
   */
  navigate(): void {
    if (typeof window !== 'undefined') {
      window.location.href =
        environment.schedwiseHostname +
        '/public/' +
        this.companyInfo.id +
        '?preselectNavigation=true&locationId=' +
        this.companyLocation.id +
        '&lang=' +
        this.translocoService.getActiveLang();
    }
  }
}
