<div class="about-container d-flex fd-column" *transloco="let t">
  <h2>{{ t("about") }}</h2>
  @if(companyInfo.description){
  <p class="mb-32">{{ companyInfo.description }}</p>
  }
  <div class="map-container">
    <div class="iframe-container">
      <iframe
        class="responsive-iframe"
        [src]="googleMapUrl"
        allowfullscreen=""
      ></iframe>
    </div>
  </div>
  <app-opening-times [companyLocation]="companyLocation"></app-opening-times>
</div>
